import React from "react";

import { ClassColor, SeasonDto } from "@justraviga/classmanager-sdk";

import { coursePriceDisplay } from "../../../../../classUtils";
import { useClassPreview } from "../../../../../data/useClassPreview";
import { useTaxRates } from "../../../../../data/useTaxRates";
import { ClassEntity } from "../../../../../entities";
import { formatMoneyFromInteger } from "../../../../../intlFormatter";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import {
  CourseImageProps,
  HorizontalSeparatorComponent,
} from "../../../../interfaces";
import {
  PreviewSheetContent,
  PreviewSheetContentDetailSection,
  PreviewSheetContentDetailsRow,
  PreviewSheetContentInfoPairRow,
  PreviewSheetContentInfoRowStatus,
  PreviewSheetContentSubTitle,
  PreviewSheetContentTitle,
} from "../../../../preview/PreviewSheetContent";
import { SeasonStatusChip } from "../../seasons/SeasonStatusChip";

interface CoursePreviewProps {
  courseEntity: ClassEntity;
  season: SeasonDto;
  CourseImage: React.FC<CourseImageProps>;
  HorizontalSeparator: HorizontalSeparatorComponent;
  ColorIndicator: React.FC<{ color: ClassColor }>;
}

export const CompanyCoursePreview = ({
  courseEntity,
  season,
  CourseImage,
  HorizontalSeparator,
  ColorIndicator,
}: CoursePreviewProps) => {
  const { Text, View } = useGenericComponents();
  const {
    description,
    weekDay,
    timeFrame,
    duration,
    startDate,
    endDate,
    location,
    instructor,
    price,
    waitingEnrolment,
    capacity,
    enrolmentDesc,
    availableSpaces,
    ageDesc,
    trialSettings,
  } = useClassPreview({
    classEntity: courseEntity,
    season,
  });
  const { class: courseDto, settings } = courseEntity;

  const { taxRates, hasTaxEnabled } = useTaxRates();
  const taxRate = taxRates.find(rate => rate.id === settings.taxRateId)?.rate;

  return (
    <PreviewSheetContent>
      <View className={"pb-4"}>
        <CourseImage course={courseDto} />
      </View>

      <PreviewSheetContentDetailSection>
        <View className="flex flex-row justify-between space-x-1">
          <View className={"flex-grow flex-1"}>
            <PreviewSheetContentTitle title={courseDto.name} />
          </View>
          <SeasonStatusChip season={season} />
        </View>
      </PreviewSheetContentDetailSection>

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentDetailsRow>{weekDay}</PreviewSheetContentDetailsRow>
        <PreviewSheetContentDetailsRow>
          {timeFrame} ({duration})
        </PreviewSheetContentDetailsRow>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle
          title="Schedule"
          icon="calendarClearOutline"
        />
        <PreviewSheetContentInfoPairRow left={"Start date"} right={startDate} />
        <PreviewSheetContentInfoPairRow left={"End date"} right={endDate} />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Location" icon="locationOutline" />
        <PreviewSheetContentDetailsRow>
          {location}
        </PreviewSheetContentDetailsRow>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle
          title="Teacher/Instructor"
          icon="personOutline"
        />
        <PreviewSheetContentDetailsRow>
          {instructor}
        </PreviewSheetContentDetailsRow>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Pricing" icon="cashOutline" />
        <PreviewSheetContentDetailsRow>
          {coursePriceDisplay(price, season.pricingScheme)}
        </PreviewSheetContentDetailsRow>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Enrollments" icon="peopleOutline" />
        <PreviewSheetContentInfoPairRow
          left={enrolmentDesc}
          right={availableSpaces.text}
        />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Waiting" icon="timeOutline" />
        <PreviewSheetContentDetailsRow>
          {waitingEnrolment}
        </PreviewSheetContentDetailsRow>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Class details" />
        <PreviewSheetContentDetailsRow>
          Description
        </PreviewSheetContentDetailsRow>
        <Text className={"text-grey-900"}>{description}</Text>
        <PreviewSheetContentInfoPairRow
          left={"Age"}
          right={ageDesc}
          justify={"start"}
        />
        <PreviewSheetContentInfoPairRow
          left={"Capacity"}
          right={String(capacity)}
          justify={"start"}
        />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Customization" />
        <PreviewSheetContentDetailsRow>Color</PreviewSheetContentDetailsRow>
        <ColorIndicator color={courseDto.colorId} />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Trials" />
        <PreviewSheetContentInfoRowStatus
          content="Allow trials"
          status={settings.trialsEnabled}
        />
        {settings.trialsEnabled && trialSettings && (
          <>
            <PreviewSheetContentInfoPairRow
              left={"Trial fee / student"}
              right={formatMoneyFromInteger(trialSettings.price)}
              justify={"start"}
            />
            <PreviewSheetContentInfoPairRow
              left={"Trial capacity"}
              right={String(trialSettings.maxCapacityPerLesson)}
              justify={"start"}
            />
          </>
        )}
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Portal enrollment" />
        <PreviewSheetContentInfoRowStatus
          content="Allow enrollment via Member Portal"
          status={settings.portalEnrolmentEnabled}
        />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Billing" />
        <PreviewSheetContentInfoRowStatus
          content="Apply tax"
          status={hasTaxEnabled && !!taxRate}
        />
        {hasTaxEnabled && !!taxRate && (
          <PreviewSheetContentInfoPairRow
            left={"Tax amount"}
            right={`${taxRate}%`}
            justify={"start"}
          />
        )}
        <PreviewSheetContentInfoRowStatus
          content="Apply global discount schemes"
          status={settings.discountable}
        />
      </PreviewSheetContentDetailSection>
    </PreviewSheetContent>
  );
};
