import { PermissionLayoutProps } from "shared/components";
import { cn, useAlert, useBreakpoint, usePermission } from "shared/lib";

import { InteractionOverlay } from "@/modules/common/overlays/InteractionOverlay";

const permissionMessage =
  "You do not have the necessary permissions for this. Contact the owner of your organization.";

export const ProtectedOverlay = ({
  protector,
  permission,
  children,
  shape,
}: PermissionLayoutProps) => {
  const { hasPermission } = usePermission();
  const { showAlert } = useAlert();
  const { md: isDesktop } = useBreakpoint();

  const permissionDenied =
    !!protector || (!!permission && !hasPermission(permission));
  const permissionDeniedMessage = protector ?? permissionMessage;

  if (!permissionDenied) {
    return children;
  }

  if (isDesktop) {
    return (
      <InteractionOverlay
        enabled={permissionDenied}
        message={permissionDeniedMessage}
        shape={shape}>
        {children}
      </InteractionOverlay>
    );
  }

  return (
    <div
      className="relative flex w-full"
      onClick={event => event.stopPropagation()}>
      <div className="opacity-30">{children}</div>
      <div
        data-testid="protected-overlay-overlay"
        className={cn("absolute inset-0", {
          "rounded-full": shape === "rounded-full",
          rounded: shape === "rounded",
        })}
        onClick={() =>
          showAlert({
            content: permissionDeniedMessage,
            variant: "important",
          })
        }></div>
    </div>
  );
};
