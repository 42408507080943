import { useEffect, useState } from "react";

import {
  CreateDiscountSchemeRequest,
  DiscountSchemeApplication,
  DiscountSchemeDto,
  DiscountSchemeType,
  DiscountSchemeValueDto,
  DiscountType,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { Api } from "../../api";
import { IconName } from "../../availableIcons";
import {
  DiscountSchemeApplicationDetailProps,
  discountSchemeFormIcon,
  discountSchemeSubtitle,
  getDiscountSchemeApplicationDetails,
} from "../../discountScheme";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";
import { UseApi } from "../apiQueryFactory";
import { useFormActions } from "../FormActionsProvider";
import { useCurrencySymbol } from "../useCurrencySymbol";

export type DiscountSchemeFormStepType = 1 | 2 | 3;
export type DiscountSchemeCreateFormProps = {
  onFinalSuccess: (record: DiscountSchemeDto) => void;
  description: string;
};

export type DiscountSchemeTierCreateFormProps = {
  onCreateSuccess: () => void;
  discountScheme: DiscountSchemeDto;
  quantity: number;
};

export type DiscountSchemeTierUpdateFormProps = {
  model: DiscountSchemeValueDto;
  discountScheme: DiscountSchemeDto;
  onUpdateSuccess: () => void;
};

export type DiscountSchemeTierFormBannerProps = {
  discountScheme: DiscountSchemeDto;
  quantity: number;
};

type Schema = CreateDiscountSchemeRequest;
export const useDiscountSchemeCreateForm = (
  useApi: UseApi,
  api: Api,
): {
  formHook: FormDefinition<Schema>;
  step: DiscountSchemeFormStepType;
  setStep: (step: DiscountSchemeFormStepType) => void;
  submitFunction: (formData: Schema) => Promise<Schema>;
  onSuccess: () => void;
} => {
  const [formValues, setFormValues] = useState<Partial<Schema>>({
    enabled: true,
  });
  const { setAllowCreateAdditional, setCreateButtonText, setSubmitDisabled } =
    useFormActions();
  const builder = new FormDefinitionBuilder<Schema>();
  const discountSchemes =
    useApi("listDiscountScheme", {}, {})?.data?.data ?? [];
  const currencySymbol = useCurrencySymbol();
  const [step, setStep] = useState<DiscountSchemeFormStepType>(1);
  const studentQtyByFamily = DiscountSchemeType.StudentQtyByFamily;
  const courseQtyByStudent = DiscountSchemeType.CourseQtyByStudent;
  const courseQtyByFamily = DiscountSchemeType.CourseQtyByFamily;
  const isDisabled = (schemeType: DiscountSchemeType) => {
    return discountSchemes.some(scheme => scheme.scheme === schemeType);
  };
  const getApplicationDescription = (
    application: DiscountSchemeApplication,
  ): DiscountSchemeApplicationDetailProps | undefined => {
    if (!formValues.scheme || !formValues.type) {
      return undefined;
    }

    return getDiscountSchemeApplicationDetails({
      type: formValues.type,
      scheme: formValues.scheme,
      application,
    } as DiscountSchemeDto);
  };

  const getDescription = (application: DiscountSchemeApplication) => {
    return getApplicationDescription(application)?.formDescription;
  };

  const getLabel = (application: DiscountSchemeApplication) => {
    return getApplicationDescription(application)?.formLabel || "";
  };

  const getIcon = (application: DiscountSchemeApplication): IconName => {
    return getApplicationDescription(application)?.detailIcon as IconName;
  };

  //it is important to use useEffect here to prevent the stack trace error
  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Continue");
    setSubmitDisabled(discountSchemes.length === 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountSchemes.length]);

  builder
    .conditional(["scheme"], ["scheme"], () => {
      return step === 1;
    })
    .conditional(["type"], ["type"], () => {
      return step === 2;
    })
    .conditional(["application"], ["application"], () => {
      return step === 3;
    });

  builder.bigRadio("scheme", {
    cols: 1,
    required: step === 1,
    options: [
      {
        label: enumLabel("discountSchemeType", studentQtyByFamily),
        description: discountSchemeSubtitle[studentQtyByFamily],
        value: studentQtyByFamily,
        icon: discountSchemeFormIcon[studentQtyByFamily],
        disabled: isDisabled(studentQtyByFamily),
      },
      {
        label: enumLabel("discountSchemeType", courseQtyByStudent),
        description: discountSchemeSubtitle[courseQtyByStudent],
        value: courseQtyByStudent,
        icon: discountSchemeFormIcon[courseQtyByStudent],
        disabled: isDisabled(courseQtyByStudent),
      },
      {
        label: enumLabel("discountSchemeType", courseQtyByFamily),
        description: discountSchemeSubtitle[courseQtyByFamily],
        value: courseQtyByFamily,
        icon: discountSchemeFormIcon[courseQtyByFamily],
        disabled: isDisabled(courseQtyByFamily),
      },
    ],
  });

  builder.bigRadio("type", {
    cols: 1,
    required: step === 2,
    options: [
      {
        label: enumLabel("discountType", DiscountType.Percent),
        description: "For example, 10% off tuition.",
        value: DiscountType.Percent,
        icon: "percentageOutline",
      },
      {
        label: enumLabel("discountType", DiscountType.Fixed),
        description: `For example, ${currencySymbol}10 off tuition.`,
        value: DiscountType.Fixed,
        icon: "cashOutline",
      },
    ],
  });

  builder.bigRadio("application", {
    cols: 1,
    required: step === 3,
    options: [
      {
        label: getLabel(DiscountSchemeApplication.IndividualItem),
        description: getDescription(DiscountSchemeApplication.IndividualItem),
        value: DiscountSchemeApplication.IndividualItem,
        icon: getIcon(DiscountSchemeApplication.IndividualItem),
      },
      {
        label: getLabel(DiscountSchemeApplication.TotalTuition),
        description: getDescription(DiscountSchemeApplication.TotalTuition),
        value: DiscountSchemeApplication.TotalTuition,
        icon: getIcon(DiscountSchemeApplication.TotalTuition),
      },
    ],
  });

  const data = match(step)
    .with(1, () => {
      return {
        onSuccess: () => setStep(2),
        submitFunction: (formData: Schema) => {
          const newValues = { ...formData, ...formValues };
          setFormValues(newValues);

          return Promise.resolve(newValues);
        },
      };
    })
    .with(2, () => {
      return {
        onSuccess: () => setStep(3),
        submitFunction: (formData: Schema) => {
          const newValues = { ...formData, ...formValues };
          setFormValues(newValues);

          return Promise.resolve(newValues);
        },
      };
    })
    .with(3, () => {
      setCreateButtonText("Create");

      return {
        onSuccess: () => {},
        submitFunction: (formData: Schema) => {
          const finalValues = { ...formData, ...formValues };

          setCreateButtonText("Create");
          setFormValues(finalValues);

          return makeDiscountSchemeCreateRequest({ api, data: finalValues });
        },
      };
    })
    .run();

  return {
    formHook: builder.getDefinition() as FormDefinition<Schema>,
    step,
    setStep,
    ...data,
  };
};

export const makeDiscountSchemeCreateRequest = ({
  api,
  data,
}: {
  api: Api;
  data: Schema;
}) => {
  return api.discountSchemes.createDiscountScheme({
    createDiscountSchemeRequest: data,
  });
};
