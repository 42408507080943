import { AccountPermissionSet } from "@justraviga/classmanager-sdk";

export type Permission =
  | "attendance:manage"
  | "attendance:view"
  | "customFields:manage"
  | "discounts:manage"
  | "enrolments:manage"
  | "enrolments:view"
  | "financial:delete"
  | "financial:manage"
  | "financial:view"
  | "members:manage"
  | "members:view"
  | "messaging:manage"
  | "schedule:manage"
  | "schedule:view"
  | "settings:manage"
  | "staff:manage"
  | "staff:removeAccess"
  | "staff:view"
  | "waivers:manage"
  | "waivers:view";

type Role = `${AccountPermissionSet}`;

/**
 * This is a mapping of permission sets to the roles that have access to them.
 *
 * NOTES:
 *  - 'enrolments' includes trials, waiting lists, make-up lessons
 *  - 'financial:view' includes anything company-performance related, like student counts, enrolment charts, etc
 *  - 'members' includes all directly-related entities, like families, contacts, addresses, custom field responses, etc
 *  - 'schedule' includes seasons, holidays, locations, rooms
 */
export const permissionsToRoles: Record<Permission, Array<Role>> = {
  "attendance:manage": ["owner", "manager", "instructor"],
  "attendance:view": ["owner", "manager", "assistant", "instructor"],
  "customFields:manage": ["owner", "manager"],
  "discounts:manage": ["owner", "manager"],
  "enrolments:manage": ["owner", "manager", "assistant"],
  "enrolments:view": ["owner", "manager", "assistant", "instructor"],
  "financial:delete": ["owner"],
  "financial:manage": ["owner", "manager", "assistant"],
  "financial:view": ["owner", "manager", "assistant"],
  "members:manage": ["owner", "manager"],
  "members:view": ["owner", "manager", "assistant", "instructor"],
  "messaging:manage": ["owner", "manager"],
  "schedule:manage": ["owner", "manager"],
  "schedule:view": ["owner", "manager", "assistant", "instructor"],
  "settings:manage": ["owner"],
  "staff:manage": ["owner", "manager"],
  "staff:removeAccess": ["owner"],
  "staff:view": ["owner", "manager", "assistant"],
  "waivers:manage": ["owner", "manager"],
  "waivers:view": ["owner", "manager", "assistant", "instructor"],
};
