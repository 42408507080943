import { useEffect, useState } from "react";

import {
  CreateSeasonRequest,
  PricingScheme,
  SeasonDto,
  UpdateSeasonRequest,
} from "@justraviga/classmanager-sdk";

import { useTaxRates } from "../../../../data/useTaxRates";
import { getPlatformFunctions } from "../../../../platformSpecific";
import {
  makeSeasonCreateRequest,
  useSeasonFormDefinition,
  useUpdatePricingSchemeDefinition,
} from "../../../formDefinitions/seasonForm";
import { MultiStepsHeader } from "../../../forms/MultiStepsHeader";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  onSuccess: (newSeason: SeasonDto) => void;
  shouldCloseOnSuccess: () => boolean;
  closeSheet: () => void;
  setTitle: (title: string) => void;
  setCreateButtonText: (createButtonText: string) => void;
  setAllowCreateAdditional: (allowCreateAdditional: boolean) => void;
}

export const SeasonCreateForm = ({
  onSuccess,
  shouldCloseOnSuccess,
  closeSheet,
  setTitle,
  setCreateButtonText,
  setAllowCreateAdditional,
}: Props) => {
  const [step, setStep] = useState(1);
  const { View } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const { defaultTaxRateId, isLoading: taxRatesLoading } = useTaxRates();

  // data
  const [detailsData, setDetailsData] = useState<
    CreateSeasonRequest | undefined
  >();

  useEffect(() => {
    const [createButtonText, title] =
      step === 1 ? ["Continue", "Create season"] : ["Create", "Create season"];
    const allowCreateAdditional = step !== 1;

    setCreateButtonText(createButtonText);
    setTitle(title);
    setAllowCreateAdditional(allowCreateAdditional);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // It's important to only start rendering the form once the tax rates have loaded
  // otherwise we don't get the registrationFeeTaxRateId populated.
  if (taxRatesLoading) {
    return null;
  }

  const defaultData: Partial<CreateSeasonRequest> = {
    registrationFeeTaxRateId: defaultTaxRateId,
  };

  const handleStepOneSuccess = (detailsData: CreateSeasonRequest) => {
    setDetailsData(detailsData);
    setStep(2);
  };

  // callbacks
  async function createSeason(formData: UpdateSeasonRequest) {
    if (detailsData === undefined) {
      return formData;
    }

    const newSeason = await makeSeasonCreateRequest({
      api,
    })({
      ...defaultData,
      ...detailsData,
      pricingScheme: formData.pricingScheme!,
    });

    onSuccess(newSeason);

    if (shouldCloseOnSuccess()) {
      closeSheet();
    } else {
      setDetailsData(undefined);
      setStep(1);
    }

    return formData;
  }

  const form =
    step === 1 ? (
      <SeasonCreateStep1Form
        setDetailsData={handleStepOneSuccess}
        defaultValues={defaultData}
      />
    ) : (
      <SeasonCreateStep2Form
        detailsData={detailsData!}
        createSeason={createSeason}
      />
    );

  return (
    <View className={"flex flex-col space-y-4"}>
      <MultiStepsHeader
        currentStep={step}
        steps={[
          { index: 1, label: "Season details" },
          { index: 2, label: "Pricing scheme", goBack: () => setStep(1) },
        ]}
      />
      {form}
    </View>
  );
};

const SeasonCreateStep1Form = ({
  setDetailsData,
  defaultValues = {} as CreateSeasonRequest,
}: {
  setDetailsData: (detailsData: CreateSeasonRequest) => void;
  defaultValues?: Partial<CreateSeasonRequest>;
}) => {
  const { Text, GenericForm } = useGenericComponents();

  return (
    <>
      <Text className={"pb-4 text-body-400 text-grey-600"}>
        Seasons can be used to plan weekly classes and enroll students on an
        annual basis.
      </Text>
      <GenericForm
        onSuccess={() => {}}
        apiRequest={formData => {
          setDetailsData(formData);
          return Promise.resolve(formData);
        }}
        defaultValues={defaultValues}
        formDefinitionHook={useSeasonFormDefinition}
      />
    </>
  );
};

const SeasonCreateStep2Form = ({
  detailsData,
  createSeason,
}: {
  detailsData: CreateSeasonRequest;
  createSeason: (formData: UpdateSeasonRequest) => Promise<UpdateSeasonRequest>;
}) => {
  const { Text, View, GenericForm } = useGenericComponents();

  return (
    <>
      <View className={"mb-2"}>
        <Text className={"mb-1 text-base font-semibold text-grey-900"}>
          How do you price your classes?
        </Text>
        <Text className={"text-body-400 text-grey-600"}>
          You can charge for your classes in a few different ways in Class
          Manager. Select one of the pricing schemes below.
        </Text>
      </View>
      <GenericForm
        onSuccess={() => {}}
        apiRequest={formData => createSeason(formData)}
        defaultValues={{
          ...detailsData,
          pricingScheme: PricingScheme.None,
        }}
        formDefinitionHook={useUpdatePricingSchemeDefinition}
      />
    </>
  );
};
