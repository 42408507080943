import {
  CreateRegistrationFeeRequest,
  RepetitionInterval,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { useTaxRates } from "../../data/useTaxRates";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import {
  getRepetitionIntervalOptions,
  monthDayToZeroYearDate,
} from "../../registrationFeeUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { useSettings } from "../useSettings";

type Schema = CreateRegistrationFeeRequest;

export const useRegistrationFeeFormDefinition = () => {
  const { Banner } = useGenericComponents();
  const { hasTaxEnabled, taxRates } = useTaxRates();
  const { locale } = useSettings("general");

  const builder = new FormDefinitionBuilder<Schema>()
    .text("name", { label: "Name", required: true })
    .select("repetitionInterval", {
      label: "Frequency",
      data: getRepetitionIntervalOptions(),
      required: true,
    })
    .conditional(["dueDate"], ["repetitionInterval"], v => {
      return v.repetitionInterval === RepetitionInterval.Annual;
    })
    .dayMonth("dueDate", {
      label: "Year start date",
      locale,
    })
    .money("amount", { label: "Per student", required: true })
    .money("maxAmountPerFamily", {
      label: "Max per family",
      description:
        "The maximum registration fee will cap the amount charged when enrolling multiple students from the same family.",
    })
    .conditional(["taxRateId"], ["taxRateId"], () => hasTaxEnabled)
    .select("taxRateId", {
      label: "Tax rate",
      data: makeTaxRateSelectOptions(taxRates),
      required: hasTaxEnabled,
      notFoundLabel: (
        <ContentPlaceholder
          icon={"helpCircleOutline"}
          title={"No tax rates yet"}
          description={"Go to settings to add tax rates"}
        />
      ),
    })
    // pro rated isn't being used yet, but backend needs it set
    .conditional(["proRated"], ["proRated"], () => false)
    .checkbox("proRated", {
      checked: false,
      label:
        "Should a student be charged a portion of registration fee when they start mid-period?",
    })
    .contentBlock(
      "after",
      "proRated",
      <Banner
        variant={"info"}
        content={
          "We will save this to your registration fees for use on seasons. If you apply the same fee to multiple seasons, a family won't be charged twice"
        }
      />,
    );

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeRegistrationFeeCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    if (formData.dueDate) {
      formData.dueDate = monthDayToZeroYearDate(formData.dueDate);
    }

    return api.registrationFees.createRegistrationFee({
      createRegistrationFeeRequest: {
        ...formData,
      },
    });
  };

export const makeRegistrationFeeUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: Schema) => {
    if (formData.dueDate) {
      formData.dueDate = monthDayToZeroYearDate(formData.dueDate);
    }

    return api.registrationFees.updateRegistrationFee({
      id,
      createRegistrationFeeRequest: formData,
    });
  };
