import { ReactElement, ReactNode } from "react";

import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  FormState,
  Path,
  UseControllerReturn,
  UseFormReturn,
} from "react-hook-form";

import { CheckboxProps } from "@shared/components/interfaces/checkbox";

import {
  BigRadio,
  CourseSelect,
  CourseSelectProps,
  LessonSelect,
  LessonSelectProps,
  useCurrencySymbol,
} from "shared/components";
import {
  BigRadioProps,
  ColorPaletteProps,
  DayMonthPickerProps,
  ImagePickerProps,
  moneyFloatToInteger,
  MoneyInputProps,
  moneyIntegerToFloat,
  MultiSelectProps,
  RichTextInputProps,
  SelectProps,
  TimePickerProps,
} from "shared/lib";

import { Checkbox } from "@/modules/common/form/Checkbox";
import { ColorPalette } from "@/modules/common/form/ColorPalette";
import {
  ColorPicker,
  ColorPickerProps,
} from "@/modules/common/form/ColorPicker";
import {
  SingleDatePicker,
  SingleDatePickerProps,
} from "@/modules/common/form/datePicker/SingleDatePicker";
import { DayMonthPicker } from "@/modules/common/form/DayMonthPicker";
import {
  DecimalInput,
  DecimalInputProps,
} from "@/modules/common/form/DecimalInput";
import { ImagePicker } from "@/modules/common/form/ImagePicker";
import {
  IntegerInput,
  IntegerInputProps,
} from "@/modules/common/form/IntegerInput";
import {
  PasswordInput,
  PasswordInputProps,
} from "@/modules/common/form/PasswordInput";
import { RichTextInput } from "@/modules/common/form/richText/RichTextInput";
import { MultiSelect } from "@/modules/common/form/select/MultiSelect";
import { Select } from "@/modules/common/form/select/Select";
import { Switch, SwitchProps } from "@/modules/common/form/Switch";
import { TextInput, TextInputProps } from "@/modules/common/form/TextInput";
import { TimePicker } from "@/modules/common/form/TimePicker";

export type FieldFunction<V extends FieldValues> = (
  handlers: UseFormReturn<V, unknown, FieldValues>,
) => ReactNode;

type Field<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerRenderProps<TFieldValues, TName>;

export type RenderFunction = (
  field: Field,
  error: string | undefined,
) => ReactElement;

export const getError = (
  name: string,
  formState: FormState<FieldValues>,
): string | undefined => {
  const error = formState.errors[name];
  return error ? String(error.message) : undefined;
};

const wrapInController =
  <T extends FieldValues>(
    name: Path<T>,
    render: RenderFunction,
  ): FieldFunction<T> =>
  ({ control, formState }: UseFormReturn<T, unknown, FieldValues>) => {
    const theirRender = ({ field }: UseControllerReturn<T>) =>
      render(field, getError(field.name, formState));

    return (
      <Controller
        control={control as Control<T>}
        render={theirRender}
        name={name}
      />
    );
  };

export const createTextInput = <T extends FieldValues>(
  name: Path<T>,
  props?: TextInputProps,
) =>
  wrapInController<T>(name, (field, error) => {
    return (
      <TextInput
        {...props}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
        error={!!error}
      />
    );
  });

export const createRichTextInput = <T extends FieldValues>(
  name: Path<T>,
  props?: RichTextInputProps,
) =>
  wrapInController<T>(name, (field, error) => {
    return (
      <RichTextInput
        {...props}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
        error={!!error}
      />
    );
  });

export const createPassword = <T extends FieldValues>(
  name: Path<T>,
  props?: PasswordInputProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <PasswordInput
        {...props}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createSelect = <T extends FieldValues>(
  name: Path<T>,
  props: SelectProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <Select
        {...props}
        name={name}
        error={!!error}
        // onBlur={field.onBlur}
        onSelect={field.onChange}
        value={field.value}
      />
    );
  });

export const createMoney = <T extends FieldValues>(
  name: Path<T>,
  props: MoneyInputProps,
) =>
  wrapInController(name, (field, error) => {
    const currency = useCurrencySymbol();
    return (
      <DecimalInput
        {...props}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={v =>
          field.onChange(v === null ? null : moneyFloatToInteger(v))
        }
        value={field.value ? moneyIntegerToFloat(field.value) : null}
        prefix={currency}
        decimalPlaces={2}
      />
    );
  });

export const createMultiSelect = <T extends FieldValues>(
  name: Path<T>,
  props: MultiSelectProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <MultiSelect
        {...props}
        name={name}
        error={!!error}
        // onBlur={field.onBlur}
        onSelect={field.onChange}
        value={field.value}
      />
    );
  });

export const createBigRadio = <T extends FieldValues>(
  name: Path<T>,
  props: BigRadioProps,
) =>
  wrapInController(name, field => {
    return (
      <BigRadio
        {...props}
        name={name}
        label={props.label}
        onChange={field.onChange}
        value={field.value}
        options={props.options}
      />
    );
  });

export const createImagePicker = <T extends FieldValues>(
  name: Path<T>,
  props?: ImagePickerProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <ImagePicker
        {...props}
        name={name}
        error={!!error}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createIntegerInput = <T extends FieldValues>(
  name: Path<T>,
  props?: IntegerInputProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <IntegerInput
        {...props}
        name={name}
        onBlur={field.onBlur}
        error={!!error}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createDecimalInput = <T extends FieldValues>(
  name: Path<T>,
  props: DecimalInputProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <DecimalInput
        {...props}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createCheckbox = <T extends FieldValues>(
  name: Path<T>,
  props: CheckboxProps,
) =>
  wrapInController(name, field => {
    return (
      <Checkbox
        {...props}
        name={name}
        onChange={field.onChange}
        checked={field.value}
      />
    );
  });

export const createColorPicker = <T extends FieldValues>(
  name: Path<T>,
  props: ColorPickerProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <ColorPicker
        {...props}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createColorPalette = <T extends FieldValues>(
  name: Path<T>,
  props: ColorPaletteProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <ColorPalette
        {...props}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createCourseSelect = <T extends FieldValues>(
  name: Path<T>,
  props: CourseSelectProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <CourseSelect
        {...props}
        Select={Select}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    );
  });

export const createLessonSelect = <T extends FieldValues>(
  name: Path<T>,
  props: LessonSelectProps,
) =>
  wrapInController(name, (field, error) => {
    return (
      <LessonSelect
        {...props}
        Select={Select}
        name={name}
        error={!!error}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value ?? [null, null, null]}
      />
    );
  });

export const createSwitch = <T extends FieldValues>(
  name: Path<T>,
  props?: SwitchProps,
) =>
  wrapInController(name, field => {
    return (
      <Switch
        {...props}
        name={name}
        onChange={field.onChange}
        checked={field.value}
      />
    );
  });

export const createDate = <T extends FieldValues>(
  name: Path<T>,
  props?: Omit<SingleDatePickerProps, "value">,
) =>
  wrapInController(name, (field, error) => {
    return (
      <SingleDatePicker
        {...props}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={!!error}
        value={field.value}
      />
    );
  });

export const createDayMonth = <T extends FieldValues>(
  name: Path<T>,
  props: Omit<DayMonthPickerProps, "value">,
) =>
  wrapInController(name, (field, error) => {
    return (
      <DayMonthPicker
        {...props}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={!!error}
        value={field.value}
      />
    );
  });

export const createTime = <T extends FieldValues>(
  name: Path<T>,
  props: Omit<TimePickerProps, "value">,
) =>
  wrapInController(name, (field, error) => {
    return (
      <TimePicker
        {...props}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={!!error}
        value={field.value}
      />
    );
  });
