import { useState } from "react";

import {
  PreviewTuitionFormSchema,
  PreviewTuitionFormStepType,
} from "./previewTuitionForm";
import { usePreviewTuitionBillData } from "./usePreviewTuitionBillData";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

export const usePreviewTuitionFirstStepForm = (
  builder: FormDefinitionBuilder<PreviewTuitionFormSchema>,
  step: PreviewTuitionFormStepType,
) => {
  const { billableMonths, selectedSeason, setSelectedSeason, seasons } =
    usePreviewTuitionBillData();
  const [billingMonth, setBillingMonth] = useState<string | undefined>(
    undefined,
  );

  builder
    .conditional(["seasonId"], ["seasonId"], v => {
      const findSeason = seasons?.data.find(season => season.id === v.seasonId);
      findSeason && setSelectedSeason(findSeason);

      return step === 1;
    })
    .conditional(["billingMonth"], ["billingMonth"], v => {
      setBillingMonth(v.billingMonth as string | undefined);
      return step === 1;
    })
    .select("seasonId", {
      label: "Season",
      required: step === 1,
      data: (seasons?.data ?? []).map(season => ({
        value: season.id,
        label: season.name,
      })),
    })
    .select("billingMonth", {
      label: "Billing month",
      required: step === 1,
      data: billableMonths,
    });

  return {
    selectedSeason,
    billingMonth,
  };
};
