import {
  CreateStudentRequest,
  StudentGender,
  UpdateStudentRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type CreateSchema = CreateStudentRequest;
type UpdateSchema = UpdateStudentRequest;

const useDefinition = () => {
  type Schema = CreateSchema & UpdateSchema;

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic information", ["firstname", "lastname"])
    .text("firstname", { label: "First name", required: true })
    .text("lastname", { label: "Last name", required: true })
    .group("Personal information", ["dateOfBirth", "gender"])
    .date("dateOfBirth", { label: "Date of birth" })
    .select("gender", {
      label: "Gender",
      data: Object.values(StudentGender).map(value => ({
        label: enumLabel("studentGender", value),
        value,
      })),
    })
    .group("Medical & consent", ["mediaConsent", "medicalInfo"])
    .text("medicalInfo", {
      label: "Medical information",
      description:
        "If your child doesn't have any medical information to provide, leave this section blank.",
    })
    .switch("mediaConsent", {
      label: "Photo & video consent",
      description:
        "Student consents to be in images posted on social media or used in online content.",
    });

  return builder.getDefinition();
};

export const useStudentCreateFormDefinition = () =>
  useDefinition() as FormDefinition<CreateSchema>;

export const useStudentUpdateFormDefinition = () =>
  useDefinition() as FormDefinition<UpdateSchema>;

export const usePortalStudentCreateFormDefinition = () =>
  useDefinition() as FormDefinition<CreateSchema>;

export const usePortalStudentUpdateFormDefinition = () =>
  useDefinition() as FormDefinition<UpdateSchema>;

export const usePortalStudentDateOfBirthChangeFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateSchema>()
    .date("dateOfBirth", { label: "Date of birth" })
    .getDefinition() as FormDefinition<UpdateSchema>;
};

export const makeStudentCreateRequest =
  ({ api, familyId }: { api: Api; familyId: string }) =>
  (formData: CreateSchema) => {
    return api.students.createStudent({
      createStudentRequest: {
        ...formData,
        familyId,
      },
    });
  };

export const makeStudentUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.students.updateStudent({
      id,
      updateStudentRequest: formData,
    });
  };
